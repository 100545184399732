@charset "UTF-8";

@-webkit-keyframes fadeInRight {
  0% {
      opacity: 0;
      -webkit-transform: translateX(20px);
      transform: translateX(20px);
  }

  100% {
      opacity: 1;
      -webkit-transform: translateX(0);
      transform: translateX(0);
  }
}

@keyframes fadeInRight {
  0% {
      opacity: 0;
      -webkit-transform: translateX(40px);
      -ms-transform: translateX(40px);
      transform: translateX(40px);
  }

  100% {
      opacity: 1;
      -webkit-transform: translateX(0);
      -ms-transform: translateX(0);
      transform: translateX(0);
  }
}

.fadeInRight {
  -webkit-animation-name: fadeInRight;
  animation-name: fadeInRight;
}

.animated {
  -webkit-animation-duration: 1s;
  animation-duration: 1s;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
  z-index: 100;
}