// Font
$font-stack: "Open Sans", "Helvetica Neue", Helvetica, Arial, sans-serif;

// Basic Colors
$navy: #93C949;           // Primary color
$dark-gray: #c2c2c2;      // Default color
$blue: #1c84c6;           // Success color
$lazur: #23c6c8;          // Info color
$yellow: #f8ac59;         // Warrning color
$red: #ed5565;            // Danger color

// Various colors
$text-color: #676a6c;           // Body text
$gray: #E8ECEF;                 // Background wrapper color
$light-gray: #d1dade;           // Default label, badget
$label-badget-color: #5e5e5e;
$light-blue: #f3f6fb;

// Spiner color and margin
$spin-color: $navy;
$spin-margin: 0 auto;

// IBOX colors ( default panel colors)
$border-color: #e7eaec;   // IBox border
$ibox-title-bg: white;    // IBox Background header
$ibox-content-bg: white;  // IBox Background content

//Sidebar width
$sidebar-width: 0px;

// Boxed layout width
$boxed-width: 1200px;
$boxed-backgound: url("patterns/shattered.png");

//Border radius for buttons
$btn-border-radius: 3px;

//Navigation
$nav-bg: #185398;
$nav-blue: #013958;
$nav-text-color: #a7b1c2;

//Colors
$sidebar-text: #61FF59; //Color of sidebar text
$light-blue: #23B9C8;

//font-awesome font directory
$fa-font-path: "../fonts" !default ;
